import { Global } from '@mantine/core';
import openSansRegular from '../fonts/OpenSans-Regular.ttf';
import openSansBold from '../fonts/OpenSans-Bold.ttf';
import robotoFlex from '../fonts/RobotoFlex-Variable.ttf';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'OpenSans',
            src: `url('${openSansRegular}') format("truetype")`
          },
        },
        {
          '@font-face': {
            fontFamily: 'OpenSans-Bold',
            src: `url('${openSansBold}') format("truetype")`
          },
        },
        {
          '@font-face': {
            fontFamily: 'RobotoFlex',
            src: `url('${robotoFlex}') format("truetype")`,
            fontWeight: 800
          },
        }
      ]}
    />
  );
}