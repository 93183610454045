import { useState } from "react";
import {
  ActionIcon,
  AppShell,
  Burger,
  Group,
  Header,
  MediaQuery,
  Navbar,
  Title,
  useMantineTheme,
  useMantineColorScheme,
  Image,
  Text
} from "@mantine/core";
import { IconSun, IconMoonStars } from '@tabler/icons';
import MainLinks from "./MainLinks";
import UserMenu from './UserMenu';
import { Outlet } from "react-router-dom";

export default function SharedLayout() {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      padding={0}
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={
        <div style={{ position: 'relative', height: "100%" }}>
          <Navbar hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }} p="xs">
            <Navbar.Section grow mt="xs">
              <MainLinks />
            </Navbar.Section>
            <Navbar.Section>
              <UserMenu />
            </Navbar.Section>
          </Navbar>
        </div>}
      header={
        <Header height={60} p="xs">
          <Group sx={{ height: '100%' }} px={20} position="apart">
            <Group>
              <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>
              <Image width={25} src="/logo.png" />
              <Title order={3} sx={{
                fontFamily: 'RobotoFlex',
                fontSize: 15,
                color: 'rgba(0, 0, 0, 0.6)'
              }}>TW<Text sx={{
                fontFamily: 'RobotoFlex',
                fontSize: 15,
                color: '#1971C2' }} span>AI</Text>NN</Title>
            </Group>
            <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
              {colorScheme === 'dark' ? <IconSun size={16} /> : <IconMoonStars size={16} />}
            </ActionIcon>
          </Group>
        </Header>}
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
    >
      <Outlet />
    </AppShell>
  );
};