import { Button, Card, Group, LoadingOverlay, Textarea, Title } from "@mantine/core";
import React, { useRef, useState } from "react";
import ApiService from "../services/api-service";
import { useAuth } from "../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";

export default function Completion() {
  const promptRef = useRef<HTMLTextAreaElement>(null);
  const resultRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const apiService = new ApiService(currentUser, params, navigate);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setLoading(true);

    const prompt = promptRef.current;
    const result = await apiService.post('completions',
      {prompt: prompt && prompt.value, max_tokens: 128},
      'create completion');

    if (result && resultRef.current) {
      resultRef.current.value = result.text;
    }

    setLoading(false);
  }

  return (
    <>
      <Card>
        <Title>Completion</Title>
        <form onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <Textarea label="Prompt"
                    ref={promptRef}
                    autosize
                    minRows={2}
                    required />
          <Group position="right" mt="xl">
            <Button disabled={loading} className="w-100" type="submit">
              Submit
            </Button>
          </Group>
          <Textarea label="Result"
                    ref={resultRef}
                    autosize
                    minRows={4}
          />
        </form>
      </Card>
    </>
  );
}
