import {useState, ReactElement} from "react";
import {Center, createStyles, Group, Table, Text, TextInput, UnstyledButton} from "@mantine/core";
import {IconChevronDown, IconChevronUp, IconSearch, IconSelector} from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart" noWrap>
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(keyFuncs: ((e: any) => string)[], data: any[], search: string) {
  const query = (search || '').toLowerCase().trim();
  return data.filter((item) => {
    return keyFuncs.some((keyFunc) => keyFunc(item)?.toLowerCase().includes(query)); }
  );
}

function sortData(
  headers: any[],
  data: any[],
  payload: { sortBy: string | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;
  const keyFuncs = headers.map(h => h.keyFunc);
  const header = headers.find(h => h.key === sortBy);
  const keyFunc = header?.keyFunc;

  if (!sortBy) {
    return filterData(keyFuncs, data, payload.search);
  }

  return filterData(keyFuncs,
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return keyFunc ? keyFunc(b)?.localeCompare(keyFunc(a)) : 0;
      }

      return keyFunc ? keyFunc(a)?.localeCompare(keyFunc(b)) : 0;
    }),
    payload.search
  );
}

export default function TableSort(props: { placeholder: string,
  headers: { key: string, label: string | ReactElement, keyFunc: (e: any) => string }[],
  data: any[], setSortedData: (data: any[]) => void, children: any }) {
  const data = props.data;
  const setSortedData = props.setSortedData;

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(props.headers, data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(sortData(props.headers, data, { sortBy, reversed: reverseSortDirection, search: value }));
  };

  return (
    <>
      <TextInput
        placeholder={ props.placeholder }
        mb="md"
        icon={<IconSearch size={14} stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table striped highlightOnHover>
        <thead>
        <tr>
          { props.headers.map(header => {
            return (
              <Th key={ header.key }
                  sorted={ sortBy === header.key }
                  reversed={ reverseSortDirection }
                  onSort={() => setSorting(header.key)}>
                { header.label }
              </Th>);
          })}
        </tr>
        </thead>
        { props.children }
      </Table>
    </>
  );
}