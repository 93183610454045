import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  Space,
  Stack,
  Text,
  TextInput,
  Tooltip
} from "@mantine/core";

import { useAuth } from "../contexts/AuthContext";
import ApiService from "../services/api-service";
import Loading from "./Loading";
import { useForm } from "@mantine/form";
import Document from "../models/Document";
import Question from "../models/Question";
import Answer from "../models/Answer";
import FileExtensionIcon from "./FileExtensionIcon";
import FileName from "./FileName";
import { IconChevronDown } from "@tabler/icons";

const URL_REGEXP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

function QuestionLoad(props: {
  question: Question, depth: number,
  setLoadedChildrenAffirmativeCount: (isCompletelyAffirmative: {
    questionCount: number, affirmativeCount: number }) => void
}): ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSelfAffirmative, setIsSelfAffirmative] = useState<boolean>(false);
  const [isCompletelyAffirmative, setIsCompletelyAffirmative] =
    useState<{ isAffirmative: boolean, childQuestionCount: number, childAffirmativeCount: number } | null>(null);
  const {currentUser} = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const fetchedAnswers = useRef<boolean>(false);

  const {question, depth, setLoadedChildrenAffirmativeCount} = props;
  const userPrompt = question.user_prompt;

  useEffect(() => {

    const fetchAnswers = async () => {
      if (question.is_category) {
        setIsSelfAffirmative(true);
        setLoading(false);
        return;
      }

      setLoading(true);
      const completeAnswers: Answer[] = [];
      let isAffirmative = false;
      for (const documentEmbedding of question.document_embeddings || []) {
        const answer = documentEmbedding.answer;
        let completeAnswer = null;
        if (!answer || !answer.id) {
          const answer = {
            document_embedding_id: documentEmbedding.id,
            question_id: question.id,
            complete_answer: true
          }
          const apiService = new ApiService(currentUser, params, navigate);
          completeAnswer = await apiService.post('answers', answer, 'get answer');
          documentEmbedding.answer = completeAnswer;
        } else {
          completeAnswer = answer;
        }
        isAffirmative = isAffirmative || completeAnswer.is_affirmative;
        completeAnswers.push(completeAnswer);
      }
      setIsSelfAffirmative(isAffirmative);

      const hasChildren = question.children && question.children.length > 0;
      if (!hasChildren || !isAffirmative) {
        const affirmativeCount = isAffirmative ? 1 : 0;
        setIsCompletelyAffirmative(
          { isAffirmative: isAffirmative, childQuestionCount: 1, childAffirmativeCount: affirmativeCount });
        setLoadedChildrenAffirmativeCount({ questionCount: 1, affirmativeCount: affirmativeCount });
      }

      setLoading(false);
    }

    if (!fetchedAnswers.current) {
      fetchedAnswers.current = true;
      fetchAnswers();
    }
  }, [question.document_embeddings, currentUser, navigate, params, question.id, question.is_category, question.children, setLoadedChildrenAffirmativeCount]);

  function setChildrenAffirmativeCount(
    childrenAffirmativeCount: { questionCount: number, affirmativeCount: number }): void {
    const areChildrenAffirmative =
      childrenAffirmativeCount.questionCount === childrenAffirmativeCount.affirmativeCount;
    const completelyAffirmative = isSelfAffirmative && areChildrenAffirmative;

    setIsCompletelyAffirmative({
      isAffirmative: completelyAffirmative,
      childQuestionCount: childrenAffirmativeCount.questionCount,
      childAffirmativeCount: childrenAffirmativeCount.affirmativeCount });
    setLoadedChildrenAffirmativeCount(childrenAffirmativeCount);
  }

  return (<>{loading ? <Loader/> : !!userPrompt ?
    <QuestionPrompt question={question}
                    depth={depth}
                    isSelfAffirmative={isSelfAffirmative}
                    isCompletelyAffirmative={isCompletelyAffirmative}></QuestionPrompt> :
    <QuestionStatus question={question}
                    depth={depth}
                    isSelfAffirmative={isSelfAffirmative}
                    isCompletelyAffirmative={isCompletelyAffirmative}
                    setChildrenAffirmativeCount={setChildrenAffirmativeCount}></QuestionStatus>}</>);
}

function QuestionPrompt(props: {question: Question, depth: number,
  isSelfAffirmative: boolean,
  isCompletelyAffirmative:
    { isAffirmative: boolean, childQuestionCount: number, childAffirmativeCount: number } | null }): ReactElement {

  const { question, depth, isSelfAffirmative } = props;

  function childrenHaveAnswers(children: Question[]): boolean {
    for (const question of children) {
      for (const documentEmbedding of question.document_embeddings || []) {
        const answer = documentEmbedding.answer;
        if (answer?.id) {
          return true;
        }
      }

      if (question.children) {
        return childrenHaveAnswers(question.children);
      }
    }
    return false;
  }

  const hasAnswers = !!question.children && childrenHaveAnswers(question.children);
  const [proceed, setProceed] = useState<boolean>(hasAnswers);
  const [correct, setCorrect] = useState<boolean>(hasAnswers);
  const [loading, setLoading] = useState<boolean>(hasAnswers);
  const [success, setSuccess] = useState<boolean>(false);

  const userPrompt = question.user_prompt;

  if (!isSelfAffirmative) {
    return (<></>);
  }

  function yes() {
    setCorrect(true);
    setProceed(true);
    setLoading(true);
  }

  function no() {
    setCorrect(false);
    setProceed(true);
    setLoading(true);
  }

  function setLoadedAndChildrenAffirmativeCount(
    childrenAffirmativeCount: { questionCount: number, affirmativeCount: number }) {
    setSuccess(childrenAffirmativeCount.affirmativeCount === childrenAffirmativeCount.questionCount);
    setLoading(false);
  }

  return (
    <>
      { !proceed ? (
        <Stack>
          <Center>{userPrompt.question}</Center>
          <Center>
            <Group>
              <Button onClick={yes}>Yes</Button>
              <Button onClick={no}>No</Button>
            </Group>
          </Center>
        </Stack>
      ) : (
        <>
          <Center sx={{ 'white-space': 'pre-wrap' }}>
            <Text sx={{ width: 500, textAlign: 'center' }}>
              { loading ? userPrompt.loading : success ? userPrompt.success : userPrompt.failure }</Text>
          </Center>
          <Divider my="sm" />
          {correct ?
            <QuestionList questions={question.children || []}
                          setChildrenAffirmativeCount={setLoadedAndChildrenAffirmativeCount}
                          depth={depth + 1} />
            :
            <div>Okay then :(</div>
          }
        </>
      )}
    </>
  );
}

function QuestionStatus(props: {question: Question, depth: number, isSelfAffirmative: boolean,
  isCompletelyAffirmative: { isAffirmative: boolean, childQuestionCount: number, childAffirmativeCount: number } | null,
  setChildrenAffirmativeCount:
    (areChildrenAffirmative: { questionCount: number, affirmativeCount: number }) => void}): ReactElement {
  const { question, isCompletelyAffirmative, setChildrenAffirmativeCount, depth } = props;
  const hasChildren = question.children && question.children.length > 0;

  function getReason(question: Question,
                     isCompletelyAffirmative:
                       { isAffirmative: boolean, childQuestionCount: number, childAffirmativeCount: number }): string {
    const isAffirmative = isCompletelyAffirmative.isAffirmative;
    if (question.is_category) {
      if (isAffirmative) {
        return 'All checks in this category passed.';
      } else {
        const failedQuestions =
          isCompletelyAffirmative.childQuestionCount - isCompletelyAffirmative.childAffirmativeCount;
        return `${failedQuestions} check(s) failed.`;
      }
    }

    const documentEmbeddings = question.document_embeddings || [];
    const matchingDocumentEmbedding = documentEmbeddings.find(de => de.answer?.is_affirmative === isAffirmative);
    const completion = matchingDocumentEmbedding?.answer?.completion;
    return completion?.text || 'Reason unknown: the answer appears to be missing';
  }

  return (<>
    <Accordion.Item value={question.id || '?'}>
      <Accordion.Control chevron={hasChildren ? <IconChevronDown size={16} /> : <></>}>
        <Group>
          { isCompletelyAffirmative === null ? <Group><Loader/></Group> : (
            <Tooltip multiline
                     width={500}
                     label={getReason(question, isCompletelyAffirmative)}>
              <Badge color={isCompletelyAffirmative.isAffirmative ? 'green' : 'red'} radius="xs">
               {isCompletelyAffirmative.isAffirmative ? 'PASS' : 'FAIL'}</Badge>
            </Tooltip>
          )}
          <Text>{ isCompletelyAffirmative === null && 'Analyzing '}{question.text}</Text>
          { isCompletelyAffirmative !== null && question.is_category && depth > 1 &&
            <Text size="sm">
              ( {isCompletelyAffirmative.childAffirmativeCount} / {isCompletelyAffirmative.childQuestionCount} )
            </Text>
          }
        </Group>
      </Accordion.Control>

      { question.children && question.children.length > 0 &&
        <Accordion.Panel>
          <QuestionList questions={question.children || []}
                        setChildrenAffirmativeCount={setChildrenAffirmativeCount}
                        depth={depth + 1} />
        </Accordion.Panel>
      }
    </Accordion.Item>
  </>);
}

function QuestionList(props: {
  questions: Question[],
  setChildrenAffirmativeCount?: (childrenAffirmativeCount: {
    questionCount: number, affirmativeCount: number }) => void,
  depth: number
}): ReactElement {
  const {questions, depth, setChildrenAffirmativeCount} = props;
  const childrenCount = questions.length;
  const loadedCount = useRef<number>(0);
  const totalAffirmativeCount = useRef<number>(0);
  const totalQuestionCount = useRef<number>(0);

  function setChildAffirmativeCount(childAffirmativeCount: {
    questionCount: number, affirmativeCount: number }) {
    loadedCount.current = loadedCount.current + 1;
    totalAffirmativeCount.current = totalAffirmativeCount.current + childAffirmativeCount.affirmativeCount;
    totalQuestionCount.current = totalQuestionCount.current + childAffirmativeCount.questionCount;

    if (loadedCount.current === childrenCount && setChildrenAffirmativeCount) {
      setChildrenAffirmativeCount(
        { questionCount: totalQuestionCount.current, affirmativeCount: totalAffirmativeCount.current});
    }
  }

  function QuestionListItem(props: { question: Question }): ReactElement {
    const { question } = props;
    return <QuestionLoad question={question} depth={depth}
                         setLoadedChildrenAffirmativeCount={setChildAffirmativeCount}></QuestionLoad>;
  }

  if (depth === 0) {
    return (<>{questions.map((question: Question) => {
      return (<QuestionListItem key={question.id} question={question} />);
    })}</>);
  } else {
    return (
      <Accordion>
        {questions.map((question: Question) => {
          return (<QuestionListItem key={question.id} question={question} />);
        })}
      </Accordion>
    );
  }
}

export default function DocumentAnalysis(props: {}): ReactElement {
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<Document | null>(null);
  const [mounted, setMounted] = useState(true);
  const [nothingFound, setNothingFound] = useState(false);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = params['projectId'];
  const documentId = searchParams.get('documentId');

  const form = useForm({
    initialValues: {
      uri: ''
    },
    validate: {
      uri: (value) => (!value.match(URL_REGEXP) ? 'Must be a valid URL' : null),
    }
  });

  useEffect(() => {

    const fetchDocument = async () => {
      if (!document && documentId) {
        const apiService = new ApiService(currentUser, params, navigate);
        const currentDocument =
          await apiService.get(`documents/${documentId}?scope=withAnswers&includes=questionsAndCategories`,
            'get document');
        setDocument(currentDocument);
      }
      setMounted(true);
    };

    fetchDocument();
  }, [currentUser, params, navigate, documentId, document]);

  async function handleSubmit(values: any) {
    setLoading(true);
    setDocument(null);
    setSearchParams({documentId: ''});

    const uri = values.uri;
    const apiService = new ApiService(currentUser, params, navigate);
    try {
      const result = await apiService.post('documents',
        {uri: uri, project_id: projectId},
        'create document');

      if (result) {
        setSearchParams({documentId: result.id});
      }
    } catch (e) {

    }

    setLoading(false);
  }

  function setChildrenAffirmativeCount(
    childrenAffirmativeCount: { questionCount: number, affirmativeCount: number }): void {
    if (childrenAffirmativeCount.affirmativeCount === 0) {
      setNothingFound(true);
    }
  }

  return (
    <Loading loading={loading} mounted={mounted}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Text size={14} sx={{ color: '#909296', fontWeight: 700 }}>ADD CONTRACT TO ANALYZE</Text>
        <Group>
          <TextInput placeholder="Paste link here"
                     {...form.getInputProps('uri')}
                     sx={{ width: 600 }}
          />
          <Button disabled={loading} className="w-100" type="submit">
            Add
          </Button>
        </Group>
      </form>
      <Space h="lg"></Space>
      { document && (
        <Card>
          <Center>
            <Group>
              <FileExtensionIcon document={document} />
              <FileName document={document} />
            </Group>
          </Center>
          <Space h="md" />
          <QuestionList questions={document.questions || []} depth={0}
                        setChildrenAffirmativeCount={setChildrenAffirmativeCount}></QuestionList>
          { nothingFound && <Center><Text>No matching patterns were found.</Text></Center>}
        </Card>
      )}
    </Loading>
  );
}
