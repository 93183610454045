import React, {useCallback, useEffect, useState} from "react";
import Project from "../../models/Project";
import { useAuth } from "../../contexts/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import ApiService from "../../services/api-service";
import { Text, Group, Divider, Space } from "@mantine/core";
import Documents from "../documents/Documents";
import ProgressBreakdown from "../ProgressBreakdown";
import EditableText from "../EditableText";
import Loading from "../Loading";

export default function ViewProject() {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const projectId = params['projectId'];

  const fetchProject = useCallback(async () => {
    setLoading(true);
    const apiService = new ApiService(currentUser, params, navigate);
    const project = await apiService.get(`projects/${projectId}?scope=withAnswers&includes=tagScores`, 'get projects');
    setProject(project);
    setMounted(true);
    setLoading(false);
  }, [currentUser, params, navigate, projectId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return (
    <div>
      <Loading loading={loading} mounted={mounted}>
        <Group spacing={8} sx={{ maxHeight: 30}}>
          <Text>{ project?.account?.name }</Text>
            {project &&
              <EditableText object={project} fieldName="name" endPoint="projects"></EditableText>
            }
        </Group>
        <Text size={12} sx={{ color: '#909296' }}>
          { project?.modified_by?.full_name }
        </Text>
        <Space h="md" />
        <Text size={14} sx={{ color: '#909296', fontWeight: 700 }}>PROJECT INFO COMPLETENESS</Text>
        <Space h="xs" />
        { project?.tags && <ProgressBreakdown tags={project?.tags}></ProgressBreakdown> }
      </Loading>
      <Divider my="sm" variant="dotted" />
      <Documents onChange={() => fetchProject()}></Documents>
    </div>
  );
}
