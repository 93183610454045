import { Table } from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";

export default function ProjectTable(props: { records: any[] }) {
  const { accountId } = useParams();

  const navigate = useNavigate();

  function selectProject(projectId: string): void {
    navigate(`/projects/${projectId}`);
  }

  const rows = props.records.map((element) => (
    <tr key={element.id} onClick={() => selectProject(element.id)}>
      <td>{new Date(element.created_at).toLocaleString()}</td>
      { !accountId &&
        <td>{element.account?.name}</td>
      }
      <td>{element.name}</td>
    </tr>
  ));

  return (
    <Table striped highlightOnHover>
      <thead>
      <tr>
        <th>Created</th>
        { !accountId &&
          <th>Account</th>
        }
        <th>Name</th>
      </tr>
      </thead>
      <tbody>{ rows }</tbody>
    </Table>
  );
}