import { Button, Card, Divider, Center, Group, Modal, Space, Text } from "@mantine/core";
import ApiService from "../../services/api-service";
import { useAuth } from "../../contexts/AuthContext";
import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Account from "../../models/Account";
import ProjectCard from "../projects/ProjectCard";
import { IconPlus } from "@tabler/icons";
import NewProject from "../projects/NewProject";
import DocumentProgress from "../DocumentProgress";
import ProgressBreakdown from "../ProgressBreakdown";
import EditableText from "../EditableText";
import Loading from "../Loading";

export default function ViewAccount() {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [account, setAccount] = useState<Account | null>(null);
  const [newProjectOpened, setNewProjectOpened] = useState(false);

  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const accountId = params['accountId'];

  useEffect(() => {

    const fetchAccountAndProjects = async () => {
      setLoading(true);
      const apiService = new ApiService(currentUser, params, navigate);
      setAccount(await apiService.get(`accounts/${accountId}?scope=withAnswers&includes=tagScores`, 'get accounts'));
      setMounted(true);
      setLoading(false);
    };

    fetchAccountAndProjects();
  }, [currentUser, params, navigate, accountId]);

  return (
    <Loading loading={loading} mounted={mounted}>
      {account && <EditableText object={account} fieldName="name" endPoint="accounts"></EditableText>}
      <Text size={12} sx={{color: '#909296'}}>{account?.modified_by?.full_name}</Text>
      <Space h="sm"></Space>
      <Group>
        <Card radius="lg" p={10} sx={{height: 170, width: 170}} shadow="xs" withBorder>
          <Center sx={{align: 'center'}}>
            <Text size={10} sx={{textAlign: 'center', maxHeight: 10, color: '#1864AB', fontWeight: 600}}>ACCOUNT
              COMPLETENESS</Text>
          </Center>
          <Space h="xs"/>
          <Center sx={{align: 'center'}}>
            <DocumentProgress size={130} tags={account?.tags || []}/>
          </Center>
        </Card>
        {account?.tags && <ProgressBreakdown tags={account?.tags}></ProgressBreakdown>}
      </Group>
      <Divider my="sm" variant="dotted"/>
      <Group>
        {account?.projects?.map((project: any) => {
          return (<ProjectCard project={project} key={project.id}></ProjectCard>)
        })}
        <Button radius="lg" sx={{height: 170, width: 170}} leftIcon={<IconPlus size={16}/>}
                onClick={() => setNewProjectOpened(true)}>
          New Project
        </Button>
      </Group>
      <Modal
        opened={newProjectOpened}
        onClose={() => setNewProjectOpened(false)}
        title="Add New Project"
      >
        {account?.id && <NewProject accountId={account.id}></NewProject>}
      </Modal>
    </Loading>
  );
}
