import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Accounts from "./accounts/Accounts";
import Completion from "./Completion";
import History from "./History";
import Login from "./Login";
import NewAccount from "./accounts/NewAccount";
import Projects from "./projects/Projects";
import ProtectedRoute from "./ProtectedRoute";
import Questions from "./Questions";
import SharedLayout from "./SharedLayout";
import ViewAccount from "./accounts/ViewAccount";
import ViewProject from "./projects/ViewProject";
import DocumentAnalysis from "./DocumentAnalysis";
import ProjectQuestion from "./ProjectQuestion";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<SharedLayout />}>
        <Route path="/" element={<Navigate to='/default/accounts' />}/>
        <Route path=":organization/completion" element={<ProtectedRoute outlet={<Completion />} />}/>
        <Route path=":organization/history" element={<ProtectedRoute outlet={<History />} />}/>
        <Route path=":organization/accounts" element={<ProtectedRoute outlet={<Accounts />} />}/>
        <Route path=":organization/accounts/new" element={<ProtectedRoute outlet={<NewAccount />} />}/>
        <Route path=":organization/accounts/:accountId" element={<ProtectedRoute outlet={<ViewAccount />} />}/>
        <Route path=":organization/accounts/:accountId/projects" element={<ProtectedRoute outlet={<Projects />} />}/>
        <Route path=":organization/accounts/:accountId/projects/:projectId"
               element={<ProtectedRoute outlet={<ViewProject />} />}/>
        <Route path=":organization/accounts/:accountId/projects/:projectId/question"
               element={<ProtectedRoute outlet={<ProjectQuestion />} />}/>
        <Route path=":organization/accounts/:accountId/projects/:projectId/analysis"
               element={<ProtectedRoute outlet={<DocumentAnalysis />} />}/>
        <Route path=":organization/questions" element={<ProtectedRoute outlet={<Questions />} />}/>
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}
