import {Accordion, Divider, Container, Text} from "@mantine/core";
import React from "react";
import ViewAnswer from "./ViewAnswer";
import Question from "../../models/Question";

function QuestionAnswer(props: { question: any }) {
  const question = props.question;
  const firstEmbedding = question.document_embeddings[0];
  return (
    <Accordion.Item value={question.id}>
      <Accordion.Control>
        <Text weight={700}>{ question.text }</Text>
        { firstEmbedding ? <ViewAnswer prefix="Best Answer: "
                    documentEmbedding={firstEmbedding} questionId={question.id}
                    key={firstEmbedding.id}
        ></ViewAnswer> : <Text>There are no documents available to answer this question</Text> }
      </Accordion.Control>
      <Accordion.Panel>
        { question.document_embeddings.slice(1).map(function(documentEmbedding: any) {
          return (
            <Container key={documentEmbedding.id} px="0" fluid>
              <Divider my="xs" variant="dashed"></Divider>
              <ViewAnswer prefix="Alternate Answer: "documentEmbedding={documentEmbedding} questionId={question.id}
                          key={documentEmbedding?.id}
              ></ViewAnswer>
            </Container>
          )
        })}
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default function Answers(props: { questions: Question[],
  openQuestionId: string | null, setOpenQuestionId: (v: string | null) => void }) {
  const questions = props.questions;

  return (
    <Accordion value={props.openQuestionId} onChange={props.setOpenQuestionId}>
      { questions.map(function(question: any) {
        return ( <QuestionAnswer question={question} key={question.id}></QuestionAnswer> )
      })}
    </Accordion>
  )
}
