import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Group, Space, Text, TextInput} from "@mantine/core";

import { useAuth } from "../../contexts/AuthContext";
import ApiService from "../../services/api-service";
import DocumentTable from "./DocumentTable";
import Loading from "../Loading";
import {useForm} from "@mantine/form";

const URL_REGEXP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export default function Documents(props: { onChange: () => void }) {
  const [documents, setDocuments] = useState<any[]>([]);
  const [sortedDocuments, setSortedDocuments] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const projectId = params['projectId'];

  const form = useForm({
    initialValues: {
      uri: ''
    },
    validate: {
      uri: (value) => (!value.match(URL_REGEXP) ? 'Must be a valid URL' : null),
    }
  });

  useEffect(() => {

    const fetchDocuments = async () => {
      const apiService = new ApiService(currentUser, params, navigate);
      const allDocuments = await apiService.get(`documents?project_id=${projectId}&includes=activeTags`, 'get documents');
      setDocuments(allDocuments);
      setSortedDocuments([...allDocuments]);
      setMounted(true);
    };

    fetchDocuments();
  }, [currentUser, params, navigate, projectId]);

  async function handleSubmit(values: any) {
    setLoading(true);

    const uri = values.uri;
    const apiService = new ApiService(currentUser, params, navigate);
    try {
      const result = await apiService.post('documents',
        {uri: uri, project_id: projectId},
        'create document');

      if (result) {
        const allDocuments: any[] = [result].concat(documents);
        setDocuments(allDocuments);
        setSortedDocuments([...allDocuments]);
        props.onChange();
      }
    } catch (e) {

    }

    setLoading(false);
  }

  return (
    <Loading loading={loading} mounted={mounted}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Text size={14} sx={{ color: '#909296', fontWeight: 700 }}>ADD SITE OR DOCUMENT</Text>
        <Group>
          <TextInput placeholder="Paste link here"
                     {...form.getInputProps('uri')}
                     sx={{ width: 600 }}
          />
          <Button disabled={loading} className="w-100" type="submit">
            Add
          </Button>
        </Group>
      </form>
      <Space h="lg"></Space>
      <Card radius="lg" p="lg" withBorder>
        <DocumentTable data={documents} sortedData={sortedDocuments}
                       setSortedData={setSortedDocuments}></DocumentTable>
      </Card>
    </Loading>
  );
}
