import React, { useState } from 'react';
import { AuthenticationProvider } from "../providers/AuthenticationProvider";
import { BrowserRouter } from "react-router-dom";
import { ColorSchemeProvider, ColorScheme, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { CustomFonts } from "./CustomFonts";
import AppRoutes from "./AppRoutes";

function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={
        {
          fontFamily: 'OpenSans',
          headings: { fontFamily: 'OpenSans' },
          colorScheme
        }
      } withGlobalStyles withNormalizeCSS>
        <CustomFonts />
        <NotificationsProvider>
          <BrowserRouter>
            <AuthenticationProvider>
              <AppRoutes />
            </AuthenticationProvider>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
