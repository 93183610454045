import Document from "../models/Document";
import {IconFileUnknown} from "@tabler/icons";
import React from "react";
import {ReactComponent as IconPdf} from "../icons/pdf.svg";
import {ReactComponent as IconPptx} from "../icons/pptx.svg";
import {ReactComponent as IconDocx} from "../icons/docx.svg";
import { getExtension } from "./FileName";

const EXTENSION_ICONS: { [key: string]: any } = {
  pdf: <IconPdf fill="#c11e07" width={20}></IconPdf>,
  pptx: <IconPptx fill="#c24f32" width={20}></IconPptx>,
  docx: <IconDocx fill="#2372ba" width={20}></IconDocx>
};

export default function FileExtensionIcon(props: { document: Document }) {
  const { document } = props;
  const extension = getExtension(document.source);
  return ((extension && EXTENSION_ICONS[extension]) || <IconFileUnknown></IconFileUnknown>);
}
