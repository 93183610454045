import { Chip } from '@mantine/core';
import Tag from "../models/Tag";
import TableSort from "./TableSort";

function TableBody(props: { data: any[] }) {
  return (<tbody>
    { props.data.map((element) => {
      return (
        <tr key={element.id}>
          <td>{new Date(element.created_at).toLocaleString()}</td>
          <td>{element.tags.map((tag: Tag)=> {
            return (<Chip key={tag.id}>{ tag.name }</Chip>);
          })}</td>
          <td>{element.text}</td>
          <td>{element.expected_answer}</td>
        </tr>
      );
    }) }
  </tbody>);
}

function getTagString(dataRow: any): any {
  return dataRow.tags.map((t: any) => t.name).sort().join("|");
}

export default function QuestionTable(props: {
  data: any[], sortedData: any[], setSortedData: (data: any[]) => void }) {
  return (
    <TableSort placeholder="Search questions" headers={[
      { key: 'created', keyFunc: e => e['created_at'], label: 'Created' },
      { key: 'tags', keyFunc: getTagString, label: 'Tags' },
      { key: 'text', keyFunc: e => e['text'], label: 'Question' },
      { key: 'expected_answer', keyFunc: e => e['expected_answer'], label: 'Expected Answer' }
    ]} data={ props.data } setSortedData={ props.setSortedData }>
      <TableBody data={ props.sortedData }></TableBody>
    </TableSort>);
}
