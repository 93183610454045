import {Anchor, Chip, Modal, Text} from "@mantine/core";
import React, { ReactElement, useState } from "react";
import TableSort from "../TableSort";
import Tag from "../../models/Tag";
import FileExtensionIcon from "../FileExtensionIcon";
import FileName, { getFileName } from "../FileName";
import {Link} from "react-router-dom";

function DocumentRow(props: { element: any }): ReactElement {
  const element = props.element;
  const [textOpened, setTextOpened] = useState(false);
  const [htmlOpened, setHtmlOpened] = useState(false);
  const [summaryOpened, setSummaryOpened] = useState(false);

  function modal(text: string | undefined, title: string, opened: boolean, setOpened: (opened: boolean) => void) {
    return (
      <Modal opened={opened} onClose={() => setOpened(false)} title={ title } size="90%" >
        <div dangerouslySetInnerHTML={ { __html: text || '' } }
             style={ { whiteSpace: 'pre-wrap' } }>
        </div>
      </Modal>);
  }

  return (
    <tr>
      <td style={{textAlign: 'center'}}>
        <FileExtensionIcon document={element} />
      </td>
      <td>
        <FileName document={element} />
      </td>
      <td>
        <Text style={{ cursor: 'pointer' }} lineClamp={summaryOpened ? 100 : 1 } onClick={() => setSummaryOpened(!summaryOpened)}>
          { element.detailed_summary_completion?.text }
        </Text>
      </td>
      <td>
        { element.tags.map((tag: Tag) => {
          return (<Chip size="xs" key={tag.id}>{ tag.name }</Chip>)
        })}
      </td>
      <td>
        {new Date(element.created_at).toLocaleDateString()}
      </td>
      <td>
        { modal(element.text, 'Text Preview', textOpened, setTextOpened) }
        { modal(element.html, 'HTML Preview', htmlOpened, setHtmlOpened) }
        <Anchor onClick={() => setHtmlOpened(true)}>HTML</Anchor>&nbsp;|&nbsp;
        <Anchor onClick={() => setTextOpened(true)}>Text</Anchor>&nbsp;|&nbsp;
        <Link to={`analysis?documentId=${element.id}`}>Analyze</Link>
      </td>
    </tr>
  );
}

function TableBody(props: { data: any[] }) {
  return (<tbody>
  { props.data.map((element) => {
    return (
      <DocumentRow key={element.id} element={ element }></DocumentRow>
    );
  }) }
  </tbody>);
}

export default function DocumentTable(props: {
  data: any[], sortedData: any[], setSortedData: (data: any[]) => void }) {

  return (
    <TableSort placeholder="Search documents" headers={[
      { key: 'source', keyFunc: e => e['source'], label: '' },
      { key: 'title', keyFunc: e => getFileName(e), label: 'File' },
      { key: 'summary', keyFunc: e => e['general_summary_completion'].text, label: 'Summary' },
      { key: 'tags', keyFunc: e => e['tags'][0]?.name, label: 'Tags' },
      { key: 'created_at', keyFunc: e => e['created_at'], label: 'Uploaded' },
      { key: 'text', keyFunc: e => e['text'], label: 'Preview' }
    ]} data={ props.data } setSortedData={ props.setSortedData }>
      <TableBody data={ props.sortedData }></TableBody>
    </TableSort>);
}
