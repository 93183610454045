import React, { useRef, useState } from "react"
import {
  Group,
  Button,
  Card,
  Alert,
  TextInput,
  PasswordInput,
  Title,
  Stack,
  Center, Anchor, Space
} from "@mantine/core"
import { IconAlertCircle } from '@tabler/icons';
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import { GoogleAuthProvider } from "firebase/auth";

export default function Login() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null)
  const { signIn, signInWithProvider } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e: any) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      const email = emailRef.current;
      const password = passwordRef.current;
      if (email && password) {
        await signIn(email.value, password.value)
        navigate("/")
      }
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  async function signInGoogle(e: any) {
    const provider = new GoogleAuthProvider();
    await signInWithProvider(provider);
    navigate("/");
  }

  return (
    <>
      <div style={{ width: 340, margin: 'auto' }}>
        <Space h="lg" />
        <Card shadow="sm" p="lg">
          <Stack>
            <Title align="center" order={2}>Log In</Title>
            {error && <Alert icon={<IconAlertCircle size={16} />} color="red">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <TextInput label="Email" type="email" ref={emailRef} required />
              <PasswordInput label="Password" type="password" ref={passwordRef} required />
              <Group position="right" mt="xl">
                <Button disabled={loading} className="w-100" type="submit">
                  Log In
                </Button>
              </Group>
            </form>
            <Center>
              <Anchor component={Link} to="/forgot-password">Forgot Password?</Anchor>
            </Center>
          </Stack>
        </Card>
        {/*<div className="w-100 text-center mt-2">
          Need an account? <Link to="/signup">Sign Up</Link>
        </div>*/}
        <Space h="md" />
        <Center>
          <Anchor component={Link} to="#" onClick={signInGoogle}>Log in with Google</Anchor>
        </Center>
      </div>
    </>
  )
}
