import { Button, Card, Center, Space, Text } from "@mantine/core";
import React from "react";
import Project from "../../models/Project";
import {useNavigate} from "react-router-dom";

export default function ProjectCard(props: { project: Project }) {
  const navigate = useNavigate();
  const project = props.project;

  function selectProject(project?: Project): void {
    navigate(`projects/${project?.id}`);
  }

  return (
    <Card radius="lg" shadow="xs" sx={{ height: 170, width: 170}} withBorder onClick={() => selectProject(project)}>
      <Text size={14} weight={600}>{ project.name }</Text>
      <Text size={10} sx={{ color: '#909296', height: '38%' }}>{ project.description }</Text>
      <Space h="sm"></Space>
      <Center>
        <Text size='xs' align='right' sx={{fontWeight: 800}}>
          { project.documents?.length }&nbsp;
        </Text>
        <Text size={10} sx={{ color: '#909296' }} align='left'>
          File{ project.documents?.length !== 1 && 's' }
        </Text>
      </Center>
      <Button fullWidth radius="md" size="xs"><Text sx={{ fontFamily: 'OpenSans-Bold' }}>Select</Text></Button>
    </Card>
  );
}