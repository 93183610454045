import React from "react";
import Document from "../models/Document";

export function getExtension(source?: string): string | undefined {
  return source?.split('/')[1];
}

export function getFileName(element: any): string {
  const index = element.title_index > 0 ? ` (${element.title_index})` : '';
  return `${element.title}.${getExtension(element.source)}${index}`;
}

export default function FileName(props: { document: Document }) {
  const { document } = props;
  return <a href={ document.uri } style={{ whiteSpace: 'nowrap' }}>{ getFileName(document) }</a>
}
