import { User } from "firebase/auth";
import {showNotification} from "@mantine/notifications";
import ErrorIcon from "./ErrorIcon";

export default class ApiService {
  protected currentUser: User;

  constructor(currentUser: User | null | undefined, private params: any, private navigate: any) {
    if (!currentUser) {
      throw new Error('User is not logged in');
    }
    this.currentUser = currentUser;
  }

  private getUrl(relativeUrl: string): string {
    let baseUrl = `${window.location.protocol}//${window.location.hostname}`;

    if (window.location.hostname === 'localhost') {
      baseUrl = `${baseUrl}:3000`;
    }

    return `${baseUrl}/api/0.1/${this.params['organization']}/${relativeUrl}`;
  }

  async request(endpoint: string, method: string, body: any, description?: string): Promise<any> {
    const url = this.getUrl(endpoint);
    const requestBody = body ? JSON.stringify(body) : undefined;
    const token = await this.currentUser.getIdToken();
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`
      },
      body: requestBody
    };

    const response = await fetch(url, options);
    const responseBody = await response.json();
    if (response.status >= 200 && response.status < 300) {
      return responseBody;
    } else if (response.status === 401) {
      console.error('Unauthorized.');
      this.navigate("/login");
    } else {
      console.error(responseBody);
      const title = description ? `Unable to ${description}` : 'Unknown Error';
      const errorMessage = responseBody.isKnownError ?
        responseBody.message : 'There was an issue with your request, please try again later';

      showNotification({
        title: title,
        icon: ErrorIcon(),
        message: errorMessage,
        color: 'red'
      });
      return null;
    }
  }

  async post(endpoint: string, body: any, description?: string): Promise<any> {
    return this.request(endpoint, 'POST', body, description);
  }

  async get(endpoint: string, description?: string): Promise<any> {
    return this.request(endpoint, 'GET', undefined, description);
  }

  async put(endpoint: string, body: any, description?: string): Promise<any> {
    return this.request(endpoint, 'PUT', body, description);
  }
}
