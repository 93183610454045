import React, {useEffect, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import firebase, {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  UserCredential,
  AuthProvider
} from "firebase/auth";
import {auth} from "../firebase-config"

export const AuthenticationProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>()
  const [loading, setLoading] = useState(true)

  function creatUser(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signInWithProvider(authProvider: AuthProvider): Promise<UserCredential> {
    return signInWithPopup(auth, authProvider);
  }

  function signOut() {
    return auth.signOut();
  }

  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  async function updateUserEmail(email: string): Promise<void> {
    if (currentUser) {
      return updateEmail(currentUser, email);
    }
  }

  async function updateUserPassword(password: string): Promise<void> {
    if (currentUser) {
      return updatePassword(currentUser, password);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: firebase.User | null) => {
      setCurrentUser(user);
      setLoading(false);
    })

    return unsubscribe;
  }, [])

  const value = {
    currentUser,
    creatUser,
    signIn,
    signInWithProvider,
    signOut,
    resetPassword,
    updateUserEmail,
    updateUserPassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}