import React, {useRef, useState} from "react";
import { Button, Card, Group, LoadingOverlay, TextInput } from "@mantine/core";
import ApiService from "../../services/api-service";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";

export default function NewAccount() {
  const nameRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  async function handleSubmit(event: any) {
    const apiService = new ApiService(currentUser, params, navigate);

    event.preventDefault();
    setLoading(true);

    const name = nameRef.current;
    const result = await apiService.post('accounts',
      { name: name && name.value},
      'create account');

    setLoading(false);
    navigate(result.id);
  }

  return (
    <Card>
      <form onSubmit={ handleSubmit }>
        <LoadingOverlay visible={loading} />
        <TextInput label="Account Name"
                   ref={nameRef}
                   required />
        <Group position="right" mt="xl">
          <Button disabled={loading} className="w-100" type="submit">
            Add
          </Button>
        </Group>
      </form>
    </Card>
  )
}
