import React, {useEffect, useState} from "react";
import { Button, Group, Modal } from "@mantine/core";
import { IconPlus } from '@tabler/icons';

import { useAuth } from "../../contexts/AuthContext";
import ApiService from "../../services/api-service";
import Account from "../../models/Account";
import AccountCard from "./AccountCard";
import NewAccount from "./NewAccount";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../Loading";

export default function Accounts() {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [newAccountOpened, setNewAccountOpened] = useState(false);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      const apiService = new ApiService(currentUser, params, navigate);
      setAccounts(await apiService.get('accounts?scope=withAnswers&includes=tagScores', 'get accounts'));
      setMounted(true);
      setLoading(false);
    };

    fetchAccounts();
  }, [currentUser, params]);

  return (
    <Loading loading={loading} mounted={mounted}>
      <Group>
        {accounts && accounts.map((account: Account) => {
          return (<AccountCard key={account.id} account={account}></AccountCard>);
        })}
        <Button radius="lg" sx={{height: 170, width: 170}} leftIcon={<IconPlus size={16}/>}
                onClick={() => setNewAccountOpened(true)}>
          New Account
        </Button>
      </Group>
      <Modal
        opened={newAccountOpened}
        onClose={() => setNewAccountOpened(false)}
        title="Add New Account"
      >
        <NewAccount></NewAccount>
      </Modal>
    </Loading>
  );
}
