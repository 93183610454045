import { Table } from "@mantine/core";

export default function HistoryTable(props: { records: any[] }) {
  const rows = props.records.map((element) => (
    <tr key={element.id}>
      <td>{new Date(element.created_at).toLocaleString()}</td>
      <td>{element.model}</td>
      <td>{element.prompt}</td>
      <td>{element.text}</td>
    </tr>
  ));

  return (
    <Table striped highlightOnHover>
      <thead>
      <tr>
        <th>Created</th>
        <th>Model</th>
        <th>Prompt</th>
        <th>Response</th>
      </tr>
      </thead>
      <tbody>{ rows }</tbody>
    </Table>
  );
}