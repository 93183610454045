import {Button, Card, Group, SimpleGrid, Title} from "@mantine/core";
import ApiService from "../../services/api-service";
import { useAuth } from "../../contexts/AuthContext";
import React, {useEffect, useState} from "react";
import ProjectTable from "../../components/projects/ProjectTable";
import {Link, useNavigate, useParams} from "react-router-dom";
import { IconPlus } from "@tabler/icons";

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const accountId = params['accountId'];
  const accountQuery = accountId ? `?account_id=${accountId}` : '';

  useEffect(() => {

    const fetchAccountAndProjects = async () => {
      const apiService = new ApiService(currentUser, params, navigate);

      setProjects(await apiService.get(`projects${accountQuery}`, 'get projects'));
    };

    fetchAccountAndProjects();
  }, [currentUser, params, navigate, accountId, accountQuery]);

  return (
    <Card>
      <SimpleGrid cols={2}>
        <Title>Projects</Title>
        <Group position="right">
          <Button leftIcon={ <IconPlus size={16} /> } component={Link} to={`/projects/new${accountQuery}`}>
            Create New Project
          </Button>
        </Group>
      </SimpleGrid>
      <ProjectTable records={projects}></ProjectTable>
    </Card>
  );
}
