import { Box, LoadingOverlay, Transition} from "@mantine/core";
import React from "react";

export default function Loading(props: { loading: boolean, mounted: boolean, children: any }) {
  return (
    <div style={{ margin: 0, padding: 24, width: '100%', height: '100%', position: 'relative' }}>
      <LoadingOverlay visible={ props.loading } overlayBlur={0} />
      <Transition mounted={props.mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) =>
          <Box style={styles}>
            {props.children}
          </Box>
        }
      </Transition>
    </div>
  );
}