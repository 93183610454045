import {Loader, Text, Badge, Tooltip} from "@mantine/core";

import DocumentEmbedding from "../../models/DocumentEmbedding";
import {useEffect, useRef, useState} from "react";
import ApiService from "../../services/api-service";
import {useAuth} from "../../contexts/AuthContext";
import Answer from "../../models/Answer";
import {useNavigate, useParams} from "react-router-dom";

export default function ViewAnswer(props: {
  documentEmbedding: DocumentEmbedding, questionId: string, prefix?: string
}) {
  const initialAnswer = props.documentEmbedding.answer;
  const [answer, setAnswer] = useState(initialAnswer);

  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  const answerRetrieved = useRef(false);
  const de = props.documentEmbedding;

  function colorCode(dotProduct?: number) {
    const dp = dotProduct || 0;
    const [color, accuracy] = dp >= 0.25 ? ["green", "HIGH"] :
      dp >= 0.20 ? ["lime", "MED"] :
        ["yellow", "LOW"];
    return <Badge size="lg" color={color}>{accuracy}</Badge>
  }

  function documentLabel() {
    const documentName = de.document?.title;

    return `${documentName} [${ de.start_token }...${ de.end_token }]`;
  }

  useEffect(() => {
    const createAnswer = async (answer: Answer) => {
      answer.question_id = props.questionId;
      answer.document_embedding_id = de.id;
      answer.complete_answer = true;
      const apiService = new ApiService(currentUser, params, navigate);
      setAnswer(await apiService.post('answers', answer, 'get questions'));
    };

    setAnswer(de.answer);
    if (!answerRetrieved.current) {
      answerRetrieved.current = true;
      if (initialAnswer?.id && (initialAnswer.completion_id || !initialAnswer.complete_answer)) {
        setAnswer(initialAnswer)
      } else if (initialAnswer) {
        createAnswer(initialAnswer);
      }
    }
  }, [currentUser, params, navigate, initialAnswer, de.id, de.answer, props.questionId]);

  function isAnswerReady(answer?: Answer): boolean {
    return !!answer?.id && (!!answer.completion_id || !answer.complete_answer);
  }

  return (
    <div>
      <Tooltip label={documentLabel()}>
        <Text component="span" inherit>
          { colorCode(answer?.dot_product) }
          { props.prefix}
          { isAnswerReady(answer) ?
            answer?.completion?.text : <Loader/>
          }
        </Text>
      </Tooltip>
    </div>
  )
}
