import {
  Button,
  Card,
  Group,
  LoadingOverlay,
  TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import {useAuth} from "../../contexts/AuthContext";
import ApiService from "../../services/api-service";
import {useNavigate, useParams} from "react-router-dom";

export default function NewProject(props: { accountId: string }) {
  const accountId = props.accountId;

  const [loading, setLoading] = useState(false);

  const { currentUser } = useAuth();
  const params = useParams();
  const form = useForm({
    initialValues: {
      name: '',
      existingAccountId: accountId,
      newAccountName: ''
    },

    validate: {
      name: (value: string) => (value.length < 3 ? 'Name must have at least 3 letters' : null)
    }
  });

  const navigate = useNavigate();
  const apiService = new ApiService(currentUser, params, navigate);

  async function handleSubmit(values: any) {
    setLoading(true);

    const name = values['name'];
    const description = values['description'];
    const project: any = { name: name, description: description, account_id: accountId };
    const result = await apiService.post('projects', project, 'create project');

    setLoading(false);
    navigate(`projects/${result.id}`);
  }

  return (
    <Card>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={loading} />
        <TextInput label="Project Name"
                   {...form.getInputProps('name')} />
        <TextInput label="Description or Summary"
                   {...form.getInputProps('description')} />
        <Group position="right" mt="xl">
          <Button disabled={loading} className="w-100" type="submit">
            Create
          </Button>
        </Group>
      </form>
    </Card>
  )
}
