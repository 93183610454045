import {Button, Card, Container, Group, MultiSelect, Space, TextInput} from "@mantine/core";
import ApiService from "../services/api-service";
import { useAuth } from "../contexts/AuthContext";
import React, {useEffect, useState} from "react";
import QuestionTable from "./QuestionTable";
import {useForm} from "@mantine/form";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "./Loading";

export default function Questions() {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [sortedQuestions, setSortedQuestions] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const apiService = new ApiService(currentUser, params, navigate);

  const initialTags: string[] = []
  const form = useForm({
    initialValues: {
      text: '',
      tag_ids: initialTags,
      expected_answer: null
    },
    validate: {
      text: (value) => (value.length < 5 ? 'Question must have at least 5 letters' : null),
    }
  });

  useEffect(() => {
    async function fetchQuestionsAndTags() {
      setLoading(true);
      const apiService = new ApiService(currentUser, params, navigate);
      const allQuestions = await apiService.get('questions?scope=withTags', 'get questions');
      setQuestions(allQuestions);
      setSortedQuestions([...allQuestions]);
      const tags = await apiService.get('tags', 'get tags');
      setTags(tags.map((t: any) => { return { value: t.id, label: t.name }; }))
      setLoading(false);
      setMounted(true);
    }

    fetchQuestionsAndTags();
  }, [currentUser, params, navigate]);

  async function addTag(newTag: any) {
    const apiService = new ApiService(currentUser, params, navigate);
    const tagInput = { name: newTag };
    const createdTag = await apiService.post('tags', tagInput, 'create tag');
    const tagItem = { value: createdTag.id, label: newTag };
    form.setFieldValue('tag_ids', [...form.values['tag_ids'], createdTag.id])
    setTags([...tags, tagItem])
  }

  async function handleSubmit(values: any) {
    setLoading(true);

    const result = await apiService.post('questions',
      values,
      'create question');
    const question = await apiService.get(`questions/${result.id}?scope=withTags`);
    const allQuestions = [question].concat(questions);
    setQuestions(allQuestions);
    setSortedQuestions([...allQuestions]);

    setLoading(false);
  }

  return (
    <Loading loading={loading} mounted={mounted}>
      <Container>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput label="New Question"
            {...form.getInputProps('text')}
          />
          <MultiSelect
            zIndex={10000000}
            label="Tags"
            data={tags}
            placeholder="Select tags"
            searchable
            creatable
            getCreateLabel={(query) => `+ Create "${query}"`}
            onCreate={async (query) => {
              await addTag(query);
            }}
            {...form.getInputProps('tag_ids')}
          />
          <TextInput label="Expected Answer"
            {...form.getInputProps('expected_answer')} />
          <Group position="right" mt="xl">
            <Button disabled={loading} className="w-100" type="submit">
              Create
            </Button>
          </Group>
        </form>
      </Container>
      <Space h="md" />
      <Card radius="lg" p="lg" withBorder>
        <QuestionTable data={questions} sortedData={sortedQuestions}
                       setSortedData={setSortedQuestions}></QuestionTable>
      </Card>
    </Loading>
  );
}
