import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IconChecklist, IconDatabase, IconHome, IconEye, IconMessage, Icon3dCubeSphere } from '@tabler/icons';
import { NavLink, ThemeIcon } from '@mantine/core';
import { Location } from "history";

interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  to: string;
  style?: any;
}

function isActive(location: Location, to: string): boolean {
  const pathname = location.pathname;
  return pathname === to;
}

export default function MainLinks() {
  const location = useLocation();
  const params = useParams();
  const organization = params['organization'];
  const accountId = params['accountId'];
  const projectId = params['projectId'];

  function MainLink({ icon, color, label, to, style }: MainLinkProps) {
    return (<NavLink style={style} label={label} component={Link} to={to} active={isActive(location, to)}
                     icon={
                       <ThemeIcon color={color} variant="light">
                         {icon}
                       </ThemeIcon>} />)
  }

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <MainLink icon={<IconChecklist size={16} />} color="red" label="Analysis"
                to={`/${organization}/accounts/profile/projects/profile/analysis`}></MainLink>
      <MainLink icon={<IconHome size={16} />} color="blue" label="Accounts"
                to={`/${organization}/accounts`}></MainLink>
      { accountId && accountId !== 'profile' && <MainLink icon={<IconDatabase size={16} />} color="teal" label="Projects"
                               to={`/${organization}/accounts/${accountId}`}></MainLink> }
      { projectId && projectId !== 'profile' && (
        <>
          <MainLink icon={<IconEye size={16} />} color="violet" label="Project Detail"
                               to={`/${organization}/accounts/${accountId}/projects/${projectId}`}></MainLink>
          <MainLink icon={<IconMessage size={16} />} color="orange" label="Ask Question"
                    to={`/${organization}/accounts/${accountId}/projects/${projectId}/question`}></MainLink>
        </>
        ) }
      <MainLink style={{ position: 'absolute', bottom: 10, left: 0, right: 0 }} icon={<Icon3dCubeSphere size={16} />}
                color="red" label="Admin" to={`/${organization}/questions`}></MainLink>
    </div>);
}
