import {Card, Center, Group, Text} from "@mantine/core";
import DocumentProgress from "./DocumentProgress";
import React from "react";

export default function ProgressBreakdown(props: { tags: any[]}) {
  return (
    // Card width + padding = 105 + 5 * 2  = 115
    <Group sx={{ maxWidth: Math.ceil(props.tags.length / 2) * 115 }} spacing={8}>
      { props.tags.map((tag: any, index: number) => {
          return (
            <Card key={index} radius="lg" p={5} sx={{height: 81, width: 105}} shadow="xs" withBorder>
              <Center sx={{ align: 'center' }}>
                <Text lineClamp={1} size={10} weight={600} sx={{ textAlign: 'center', maxHeight: 12, color: '#1864AB' }}>
                  {tag.name?.toUpperCase()}
                </Text>
              </Center>
              <Center sx={{ align: 'center' }}>
                <DocumentProgress size={ 62 } tags={[tag]} index={index}/>
              </Center>
            </Card>);
        }) }
    </Group>
  );
}