import {Button, Card, Grid, Group, Space, Stack, Text, Title} from "@mantine/core";
import React from "react";
import Account from "../../models/Account";
import {useNavigate} from "react-router-dom";
import DocumentProgress from "../DocumentProgress";

export default function AccountCard(props: { account: Account }) {
  const navigate = useNavigate();
  const account = props.account;
  const tags = account?.tags || [];

  function selectAccount(accountId?: string): void {
    navigate(`${accountId}`);
  }

  return (
    <Card radius="lg" sx={{ height: 170, width: 170 }} shadow="xs" withBorder onClick={() => selectAccount(account.id)}>
      <Title order={5}>{ props.account.name }</Title>
      <Text size={10} sx={{ color: '#909296' }}>{ account.modified_by?.full_name }</Text>
      <Space h="sm"></Space>
      <Grid justify="space-around">
        <DocumentProgress tags={ tags } size={ 60 }></DocumentProgress>
        <Stack spacing={2}>
          <Group spacing={4}>
            <Text size='xs' align='right' sx={{fontWeight: 800}}>
              { account.projects_count }
            </Text>
            <Text size={10} sx={{ color: '#909296' }} align='left'>
              Project{ account.projects_count !== 1 && 's'}
            </Text>
          </Group>
          <Group spacing={4}>
            <Text size='xs' align='right' sx={{fontWeight: 800}}>
              { account.documents_count }
            </Text>
            <Text size={10} sx={{ color: '#909296' }} align='left'>
              Files
            </Text>
          </Group>
        </Stack>
      </Grid>
      <Space h="sm"></Space>
      <Button radius="md" fullWidth size="xs"><Text sx={{ fontFamily: 'OpenSans-Bold' }}>Select</Text></Button>
    </Card>
  )
}