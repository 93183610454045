import Tag from "../models/Tag";
import { Text } from "@mantine/core";
import React from "react";
import { AnimatedRingProgress } from "./AnimatedRingProgress/AnimatedRingProgress";

const COLORS = ['#845EC2', '#B39CD0', '#FBEAFF', '#D65DB1',
  '#FF6F91', '#FF9671', '#FFC75F', '#F9F871'];

export default function DocumentProgress(props: { tags: Tag[], size: number, index?: number }) {
  const scores = props.tags.map((tag: Tag, tagIndex: number) => {
    const colorIndex = tagIndex + (props.index || 0);
    const percentage = (tag.score || 0) * 100.0;
    return {
      value: percentage / props.tags.length,
      color: COLORS[colorIndex % COLORS.length],
      tooltip: `${tag.name} - ${percentage.toFixed(0)}%`
    };
  }) || [];
  const totalScore = scores.reduce((total, score) => score.value + total, 0);

  return (
    <AnimatedRingProgress
      size={props.size}
      thickness={props.size / 9}
      label={
        <Text size={props.size / 6} align="center" sx={{ fontFamily: 'OpenSans', fontWeight: 800, pointerEvents: 'none' }}>
          { totalScore.toFixed(0) }
        </Text>
      }
      roundCaps
      sections={ scores }
    />
  )
}