import React from 'react';
import { IconChevronRight, IconChevronLeft, IconLogout } from '@tabler/icons';
import { UnstyledButton, Group, Avatar, Text, Box, useMantineTheme, Menu } from '@mantine/core';
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function UserMenu() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { currentUser, signOut } = useAuth();

  async function handleLogout() {
    try {
      await signOut();
      navigate("/login");
    } catch {
      console.error("Failed to log out")
    }
  }

  return (
    <Menu withArrow width={300} position="bottom" transition="pop">
      <Menu.Target>
        <UnstyledButton
          sx={{
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
          }}
        >
          <Group>
            <Avatar
              src={currentUser?.photoURL}
              radius="xl"
            />
            <Box sx={{flex: 1}}>
              <Text size="sm" weight={500}>
                {currentUser?.displayName}
              </Text>
              <Text color="dimmed" size="xs">
                {currentUser?.email}
              </Text>
            </Box>

            {theme.dir === 'ltr' ? <IconChevronRight size={18}/> : <IconChevronLeft size={18}/>}
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconLogout size={14} />} onClick={handleLogout}>Sign out</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}