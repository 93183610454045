import React, { useContext } from "react"
import firebase, { UserCredential, AuthProvider } from "firebase/auth";

export interface AuthProviderInterface {
  currentUser: firebase.User | null | undefined,
  creatUser: (email: string, password: string) => Promise<UserCredential>,
  signIn: (email: string, password: string) => Promise<UserCredential>,
  signInWithProvider: (provider: AuthProvider) => Promise<UserCredential>,
  signOut: () => Promise<void>,
  resetPassword: (email: string) =>Promise<void>,
  updateUserEmail: (email: string) => Promise <void>,
  updateUserPassword: (password: string) => Promise <void>
}

export const AuthContext = React.createContext<AuthProviderInterface | null>(null);

export function useAuth(): AuthProviderInterface {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error('Auth Provider not defined!')
  }
  return auth;
}

