import {useHover} from "@mantine/hooks";
import React, {useState} from "react";
import {useForm} from "@mantine/form";
import {useAuth} from "../contexts/AuthContext";
import ApiService from "../services/api-service";
import {TextInput} from "@mantine/core";
import { IconPencil } from '@tabler/icons';
import {useNavigate, useParams} from "react-router-dom";

export default function EditableText(props: { object: any, fieldName: string, endPoint: string }) {
  const { hovered, ref } = useHover();
  const [edit, setEdit] = useState(false);
  const initialValues: any = { id: props.object?.id };
  initialValues[props.fieldName] = props.object && props.object[props.fieldName];
  const form = useForm({
    initialValues: initialValues
  });

  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const apiService = new ApiService(currentUser, params, navigate);

  async function handleSubmit() {
    await apiService.put(`${props.endPoint}/${form.values['id']}`, form.values, `update ${props.endPoint}`);
    setEdit(false);
  }

  return (
    <form onBlur={form.onSubmit(handleSubmit)} onSubmit={form.onSubmit(handleSubmit)}>
      <span onClick={() => setEdit(true)} ref={ref}>
        <TextInput
          styles={{ input: { fontFamily: !edit && 'OpenSans-Bold', fontWeight: !edit && 600, fontSize: 16 } }}
          variant={ edit ? 'default' : 'unstyled' }
          style={{ display: "inline-flex", margin: 0 }}
          {...form.getInputProps(props.fieldName)}>
        </TextInput>
        { hovered && <IconPencil size={18} /> }
      </span>
    </form>
  )
}
