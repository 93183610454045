import {
  Autocomplete,
  Button,
  Group,
  Textarea
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import {useAuth} from "../contexts/AuthContext";
import ApiService from "../services/api-service";
import { useNavigate, useParams } from "react-router-dom";
import Account from "../models/Account";
import Project from "../models/Project";
import Question from "../models/Question";
import Answers from "./answers/Answers";
import Loading from "./Loading";

export function AutoCompleteRecord(props: { label: string, records: { id: string, name: string }[], value: string,
  onChange: (id?: string) => void, onFocus: any, onBlur: any, error: any }) {
  const [itemName, setItemName] = useState('');
  const currentRecord = props.records.find((r: any) => r.id === props.value);
  const currentName = currentRecord?.name || '';
  const items: any[] = props.records
    .filter((r: any) => !!r.name)
    .map((r: any) => { return { value: r.name, record: r } });

  useEffect(() => {
    if (currentName) {
      setItemName(currentName)
    } else if (props.records.length === 0) {
      setItemName('');
    }
  }, [currentName, props.records.length] )

  function handleChangeExistingAccount(itemName: string): void {
    setItemName(itemName);
    const item = items.find((a: any) => a.value === itemName);
    const onChange = props.onChange;
    if (item) {
      onChange(item.record?.id);
    } else {
      onChange(undefined);
    }
  }

  return (
    <Autocomplete
      label={props.label}
      data={items}
      value={itemName}
      error={props.error}
      onChange={handleChangeExistingAccount}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  )
}

export default function ProjectQuestion() {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [activeProjects, setActiveProjects] = useState<Account[]>([]);
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);

  const { currentUser } = useAuth();
  const params = useParams();
  const accountId = params['accountId'];
  const projectId = params['projectId'];
  const organization = params['organization'];
  const form = useForm({
    initialValues: {
      accountId: accountId,
      projectId: projectId,
      question: ''
    },

    validate: {
      accountId: (value: any) => { return !value ? 'Must select a valid Account' : null; },
      projectId: (value: any) => { return !value ? 'Must select a valid Project' : null; },
      question: (value: string) => { return value.length === 0 ? 'Question cannot be empty' : null; }
    }
  });

  const navigate = useNavigate();

  async function handleSubmit(values: any) {
    setLoading(true);

    const question = {
      project_id: values['projectId'],
      text: values['question'],
      adhoc: true
    }
    const apiService = new ApiService(currentUser, params, navigate);
    const result = await apiService.post('questions', question, 'create question');
    const questionWithAnswers =
      await apiService.get(`questions/${result.id}?scope=withAllAnswers&projectId=${projectId}`, 'get answers');

    setOpenQuestionId(questionWithAnswers.id);
    setQuestions([questionWithAnswers].concat(questions));

    setLoading(false);
  }

  // Filter the projects to the currently selected account
  function updateProjects(allProjects: Project[], accountId: string | undefined): Project[] {
    const accountProjects = allProjects.filter(p => p.account_id === accountId);
    setActiveProjects(accountProjects);
    return accountProjects;
  }

  async function handleAccountChange(accountId: string | undefined) {
    form.setFieldValue('accountId', accountId);

    const currentProjectId = form.values['projectId'];
    const accountProjects = updateProjects(projects, accountId);

    if (accountProjects.length === 0) {
      form.setFieldValue('projectId', undefined);
    }

    // Grab the first project if the account changes
    if (!accountProjects.find(p => p.id === currentProjectId)) {
      const newProjectId = accountProjects[0]?.id;
      await handleProjectChange(newProjectId, accountId);
    }
  }

  async function handleProjectChange(projectId: string | undefined, accountId?: string) {
    form.setFieldValue('projectId', projectId);

    // Update the current url
    const currentAccountId = accountId || form.values['accountId'];
    if (currentAccountId && projectId) {
      navigate(`/${params['organization']}/accounts/${currentAccountId}/projects/${projectId}/question`);
    }
  }

  useEffect(() => {
    const fetchAccountsAndProjects = async () => {
      setLoading(true);
      const apiService = new ApiService(currentUser, { organization }, navigate);
      const currentAccounts = await apiService.get('accounts', 'get accounts');
      const currentProjects = await apiService.get('projects', 'get projects');
      setAccounts(currentAccounts);
      setProjects(currentProjects);
      updateProjects(currentProjects, accountId);
      setLoading(false);
      setMounted(true);
    };

    fetchAccountsAndProjects();
  }, [currentUser, organization, navigate, accountId]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const apiService = new ApiService(currentUser, { organization }, navigate);
      const questions = await apiService.get(`questions?scope=withAllAnswers&project_id=${projectId}`);
      setQuestions(questions);
    };

    fetchQuestions();
  }, [currentUser, organization, navigate, projectId])

  return (
    <Loading loading={loading} mounted={mounted}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <AutoCompleteRecord
          label="Account"
          records={accounts}
          {...form.getInputProps('accountId')}
          onChange={(value) => handleAccountChange(value)}
        />
        <AutoCompleteRecord
          label="Project"
          records={activeProjects}
          {...form.getInputProps('projectId')}
          onChange={(value) => handleProjectChange(value)}
        />
        <Group mt="xl">
          <Textarea cols={50} minRows={4} {...form.getInputProps('question')}></Textarea>
          <Button disabled={loading} className="w-100" type="submit">
            Ask
          </Button>
        </Group>
        <Answers questions={ questions }
                 openQuestionId={openQuestionId} setOpenQuestionId={setOpenQuestionId}></Answers>
      </form>
    </Loading>
  )
}
