import { Card, Title } from "@mantine/core";
import ApiService from "../services/api-service";
import { useAuth } from "../contexts/AuthContext";
import { useEffect, useState} from "react";
import HistoryTable from "./HistoryTable";
import {useNavigate, useParams} from "react-router-dom";

export default function History() {
  const [history, setHistory] = useState([]);
  const { currentUser } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHistory = async () => {
      const apiService = new ApiService(currentUser, params, navigate);
      setHistory(await apiService.get('completions', 'get history'));
    };

    fetchHistory();
  }, [currentUser, params, navigate]);

  return (
    <>
      <Card>
        <Title>History</Title>
        <HistoryTable records={history}/>
      </Card>
    </>
  );
}
