import React from 'react';
import { useMantineTheme, MantineColor } from '@mantine/styles';
import {createStyles, keyframes, Tooltip} from '@mantine/core'
import { getCurveProps } from './get-curve-props';

interface CurveProps {
  value?: number;
  size?: number;
  offset: number;
  sum: number;
  thickness?: number;
  lineRoundCaps?: boolean;
  root?: boolean;
  color?: MantineColor;
  tooltip?: React.ReactNode;
}

export function Curve({
                        size,
                        value,
                        offset,
                        sum,
                        thickness,
                        root,
                        color,
                        lineRoundCaps,
                        tooltip,
                      }: CurveProps) {
  const theme = useMantineTheme();
  const stroke = theme.fn.themeColor(
    color || (theme.colorScheme === 'dark' ? 'dark' : 'gray'),
    color ? theme.fn.primaryShade() : theme.colorScheme === 'dark' ? 4 : 1,
    false
  );

  const curveProps = getCurveProps({ sum, size, thickness, value: value, offset, root });
  const total = Math.PI * curveProps.r * 2;

  const clockAnimation = keyframes({
    '0%': {
      opacity: 0,
      strokeDasharray: root ? `${total} 0` : `0 ${total}`,
      strokeDashoffset: root ? 0 : total
    },
    '100%': {
      opacity: 1
    },
  });

  const useStyles = createStyles((theme) => ({
    animatedCircle: {
      animation: `${clockAnimation} 0.5s linear`,
    },
  }));
  const { classes } = useStyles();

  return (
    <Tooltip.Floating disabled={!tooltip} label={tooltip}>
      <circle className={classes.animatedCircle}
        fill="none"
        strokeLinecap={lineRoundCaps ? 'round' : 'butt'}
        stroke={stroke}
        {...curveProps}
      />
    </Tooltip.Floating>
  );
}

Curve.displayName = '@mantine/core/Curve';