import React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

export default function ProtectedRoute({ outlet }: { outlet: JSX.Element }) {
  const { currentUser } = useAuth();

  if (currentUser) {
    return outlet;
  } else {
    return <Navigate to="/login" />;
  }
}
